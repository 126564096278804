<template>
  <div></div>
</template>

<script>
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  created() {
    loStorage.clear();
    this.$router.push("/login");
  },
};
</script>
